<template>
  <div class="tab-content">
    <div class="form-container">
      <label>Nama Paket Berlangganan</label>
      <b-form-input placeholder="" v-model="subscribe.name"></b-form-input>
      
      <label class="mt-4">Deskripsi</label>
      <b-textarea v-model="subscribe.description">
      </b-textarea>
      
      <label class="mt-4">Durasi (Hari)</label>
      <b-form-select
        v-model="subscribe.duration"
        :options="duration"
        required
        class="mrr-22 mrb-5 my-select"
      ></b-form-select>
      
      <label class="mt-4">Harga (IDR)</label>
      <b-form-input
        placeholder=""
        v-model="subscribe.price"
        @update="setDiscountPrice"
      ></b-form-input>
      
      <label class="mt-4">Discount (%)</label>
      <b-form-input
        placeholder=""
        v-model="subscribe.discount"
        @update="setDiscountPrice"
      ></b-form-input>
      
      <label class="mt-4">Harga Discount (IDR)</label>
      <b-form-input
        placeholder=""
        v-model="subscribe.discountPrice"
        readonly
      ></b-form-input>
      
      <label class="mt-4">Pilih Jenjang</label><br />
      <b-form-select
        v-model="subscribe.level_id"
        :options="levelOption"
        required
        class="mrr-22 mrb-5 my-select"
        @change="changeLevel"
      ></b-form-select>

      <label class="mt-4" v-if="majorOption && majorOption.length > 1">Pilih Jurusan</label><br />
      <b-input-group size="sm" v-if="majorOption.length > 1">
        <b-form-select
          v-model="subscribe.major_id"
          :options="majorOption"
          class="my-select"
        ></b-form-select>
      </b-input-group>

      <label class="mt-4">Label</label>
      <b-form-input
        v-model="subscribe.label"
        placeholder="Diskon 10%, Recommended, etc"
      >
      </b-form-input>
      <br />
      <br />
      <my-button
        type="blue"
        size="lg"
        rounded
        block
        disabled
        v-if="isLoading"
      >
        <b-spinner small></b-spinner>
        <span class="sr-only">Loading...</span>
      </my-button>
      <my-button type="blue" size="lg" rounded block v-else @click="save">
        SIMPAN
      </my-button>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      isLoading: false,
      duration: [
        { text: "30 Hari - 1 Bulan", value: 30 },
        { text: "60 Hari - 2 Bulan", value: 60 },
        { text: "90 Hari - 3 Bulan", value: 90 },
        { text: "180 Hari - 6 Bulan", value: 180 },
        { text: "365 Hari - 1 Tahun", value: 365 }
      ],
      moduleOption: [
        { text: "Video Bimbel", value: "vb" },
        { text: "Download Soal", value: "ds" },
        { text: "Tryout", value: "to" }
      ],
      subscribe: {
        id: 0,
        name: "",
        description: "",
        duration: 0,
        price: 0,
        discount: 0,
        discountPrice: 0,
        label: "",
        level_id: null,
        major_id: null,
        module: []
      },
      levelOption: [],
      majorOption: []
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn"
    })
  },
  created() {
    if (!this.isLoggedIn) {
      this.$router.push("/login");
    }
  },
  mounted() {
    this.levelList();
    this.getDetail();
  },
  methods: {
    ...mapActions({
      updateSubscribe: types.UPDATE_SUBSCRIBE,
      getSubscribeDetail: types.GET_SUBSCRIBE_DETAIL,
      getLevelList: types.GET_LEVEL_LIST,
      getMajorList: types.GET_MAJOR_LIST
    }),
    levelList() {
      this.getLevelList()
        .then(response => {
          var res = response;
          this.levelOption = [];
          for (var i = 0; i < res.length; i++) {
            var dataLevel = {
              value: res[i].id,
              text: res[i].name
            };
            this.levelOption.push(dataLevel);
          }
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    changeLevel() {
      this.majorList();
    },
    majorList(level=null) {
      var level_id = level ? level : this.subscribe.level_id;
      if(level_id) {
        this.getMajorList({params: {level: level_id}})
          .then(response => {
            var res = response;
            this.majorOption = [];
            for (var i = 0; i < res.length; i++) {
              var datamajor = {
                value: res[i].id,
                text: res[i].name
              };
              this.majorOption.push(datamajor);
            }
          })
          .catch(error => {
            this.showMessageBox(error, "Failed", "success");
          });
      } else {
        this.majorOption = [];
      }
    },
    setDiscountPrice() {
      this.subscribe.discountPrice =
        ((100 - this.subscribe.discount) * this.subscribe.price) / 100;
    },
    getDetail() {
      this.getSubscribeDetail(this.$route.params.id)
        .then(response => {
          var res = response;
          this.subscribe.name = res.name;
          this.subscribe.description = res.description;
          this.subscribe.label = res.label;
          this.subscribe.price = res.price;
          this.subscribe.duration = res.duration;
          this.subscribe.discountPrice =
            ((100 - res.discount) * res.price) / 100;
          this.subscribe.level_id = res.level_id;
          this.subscribe.major_id = res.major_id;
          this.subscribe.id = res.id;
          this.majorList(res.level_id);
        })
        .catch(error => {
          this.showMessageBox(error, "Failed", "success");
        });
    },
    save() {
      this.isLoading = true;
      this.updateSubscribe(this.subscribe)
        .then(response => {
          this.isLoading = false;
          this.showMessageBox("Berhasil update data", "Success", "success");
          this.getDetail();
        })
        .catch(error => {
          this.isLoading = false;
          this.showMessageBox(error, "Failed", "success");
        });
    },
    showMessageBox(message, title, variant) {
      this.$bvModal
        .msgBoxOk(message, {
          title: title,
          size: "sm",
          buttonSize: "sm",
          okVariant: variant,
          centered: true
        })
        .then(value => {
        })
        .catch(err => {
        });
    }
  }
};
</script>
